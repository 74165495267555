<template>
  <div>
    <!-- 应力拉张 -->
    <common-vue :importUrl="importUrl" :listUrl="listUrl" />
  </div>
</template>
<script>
import commonVue from "../dataMamagement/components/index.vue";
export default {
  name: "stressTensioning",
  components: { commonVue },
  data() {
    return {
      importUrl: "file/manage/traction/import", // 上传资料
      listUrl: "file/manage/traction/name" // 列表查询
    };
  }
};
</script>
<style lang="scss" scoped></style>
